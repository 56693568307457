import request from '../utils/request'


 
export function getOrganizerList() {
    return request.post('./index.php', {
        function: 'getOrganizerList'
    })
}

export function updateOrganizer(params) {
    return request.post('./index.php', {
        function: 'updateOrganizer',
        id: params.id,
        name: params.name,
        email: params.email,
        password: params.password,
        intro: params.intro,
        logo_url: params.logo_url,
        logo_transbg_url: params.logo_transbg_url,
        title: params.title,
        invoice_title: params.invoice_title,
        invoice_tax_num: params.invoice_tax_num
    })
}

export function getGuests(params) {
    return request.post('./index.php', {
        function: 'getGuests',
        ...params
    })
}

export function updateGuest(params) {
    return request.post('./index.php', {
        function: 'updateGuest',
        id: params.id,
        // session_id: params.session_id,
        name: params.name,
        titles: params.titles,
        company: params.company,
        intro: params.intro,
        avatar_url: params.avatar_url,
        role: params.role,
        company_intro: params.company_intro,
        email: params.email,
        password: params.password,
    })
}

export function removeGuest(id) {
    return request.post('./index.php', {
        function: 'removeGuest',
        id
    })
}

export function getbrandPartys(params) {
    return request.post('./index.php', {
        function: 'getbrandPartys',
        ...params
    })
}

export function updatebrandParty(params) {
    return request.post('./index.php', {
        function: 'updatebrandParty',
        id: params.id,
        name: params.name,
        company: params.company,
        intro: params.intro,
        avatar_url: params.avatar_url,
        email: params.email,
        password: params.password,
    })
}

export function removebrandParty(id) {
    return request.post('./index.php', {
        function: 'removebrandParty',
        id
    })
}


export function getMeetings(organizer_id) {
    return request.post('./index.php', {
        function: 'getMeetings',
        organizer_id
    })
}

export function getPartnerMeetings(partner_id) {
    return request.post('./index.php', {
        function: 'getPartnerMeetings',
        partner_id
    })
}

export function getMeetingPartners(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingPartners',
        meeting_id
    })
}

export function updateMeeting(params) {
    return request.post('./index.php', {
        function: 'updateMeeting',
        id: params.id,
        organizer_id: params.organizer_id,
        name: params.name,
        start_date: params.start_date,
        end_date: params.end_date,
        start_time: params.start_time,
        duration: params.duration,
        intro: params.intro,
        logo_url: params.logo_url,
        address: params.address,
        topic: params.topic,
        attend_peoples: params.attend_peoples,
        type: params.type,
        audiences: params.audiences,
        geo_address: params.geo_address,
        lon_lat: params.lon_lat,
        logo_transbg_url: params.logo_transbg_url,
        check_ticket: params.check_ticket,
    })
}

export function removeMeeting(id) {
    return request.post('./index.php', {
        function: 'removeMeeting',
        id
    })
}

export function getSessionGroups(meeting_id) {
    return request.post('./index.php', {
        function: 'getSessionGroups',
        meeting_id
    })
}

export function removeSessionGroup(id) {
    return request.post('./index.php', {
        function: 'removeSessionGroup',
        id
    })
}

export function updateSessionGroup(params) {
    return request.post('./index.php', {
        function: 'updateSessionGroup',
        id: params.id,
        meeting_id: params.meeting_id,
        name: params.name,
        date: params.date,
        start_time: params.start_time,
        duration: params.duration
    })
}

export function getSessions(meeting_id) {
    return request.post('./index.php', {
        function: 'getSessions',
        meeting_id
    })
}


export function removeSession(id) {
    return request.post('./index.php', {
        function: 'removeSession',
        id
    })
}

export function updateSession(params) {
    return request.post('./index.php', {
        function: 'updateSession',
        id: params.id,
        meeting_id: params.meeting_id,
        name: params.name,
        date: params.date,
        start_time: params.start_time,
        duration: params.duration,
        intro: params.intro,
        session_group_id: params.session_group_id,
        type: params.type,
        partner_id: params.partner_id,
        meeting_room_id: params.meeting_room_id,
        speechTime: params.speechTime,
        check_ticket: params.check_ticket,
    })
}
export function getQiniuToken(param) {
    return request.post('./index.php', {
        function: 'getQiniuToken',
        ...param
    })
}

export function getSessionInfo(param) {
    return request.post('./index.php', {
        function: 'getSessionInfo',
        ...param
    })
}

export function getMeetingInfo(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingInfo',
        meeting_id
    })
}

export function getProducts(organizer_id) {
    return request.post('./index.php', {
        function: 'getProducts',
        organizer_id
    })
}

export function removeProduct(id) {
    return request.post('./index.php', {
        function: 'removeProduct',
        id
    })
}

export function updateProduct(params) {
    return request.post('./index.php', {
        function: 'updateProduct',
        id: params.id,
        organizer_id: params.organizer_id,
        name: params.name,
        logo_url: params.logo_url,
        intro: params.intro
    })
}

export function getPartners(meeting_id) {
    return request.post('./index.php', {
        function: 'getPartners',
        meeting_id
    })
}

export function removePartner(id) {
    return request.post('./index.php', {
        function: 'removePartner',
        id
    })
}

export function updatePartner(params) {
    return request.post('./index.php', {
        function: 'updatePartner',
        ...params
    })
}


export function loginByOrganizer(organizer_email, organizer_password) {
    return request.post('./index.php', {
        function: 'loginByOrganizer',
        organizer_email,
        organizer_password
    })
}

export function getSponsorContents(meeting_id) {
    return request.post('./index.php', {
        function: 'getSponsorContents',
        meeting_id
    })
}

export function removeSponsorContent(id) {
    return request.post('./index.php', {
        function: 'removeSponsorContent',
        id
    })
}

export function updateSponsorContent(params) {
    return request.post('./index.php', {
        function: 'updateSponsorContent',
        id: params.id,
        meeting_id: params.meeting_id,
        name: params.name,
        type: params.type,
        quota: params.quota,
        amount: params.amount,
        rights: params.rights,
        quota_limit: params.quota_limit
    })
}

export function getContactInfos(meeting_id) {
    return request.post('./index.php', {
        function: 'getContactInfos',
        meeting_id
    })
}


export function removeContactInfo(id) {
    return request.post('./index.php', {
        function: 'removeContactInfo',
        id
    })
}

export function updateContactInfo(params) {
    return request.post('./index.php', {
        function: 'updateContactInfo',
        id: params.id,
        meeting_id: params.meeting_id,
        title: params.title,
        name: params.name,
        position: params.position,
        phone: params.phone,
        email: params.email,
        website: params.website,
        wechat: params.wechat,
        address: params.address
    })
}
export function getSessionDetail(id) {
    return request.post('./index.php', {
        function: 'getSessionDetail',
        id
    })
}
export function updateSessionGuests(session_id, guest_ids, guest_info = {}) {
    return request.post('./index.php', {
        function: 'updateSessionGuests',
        session_id,
        guest_ids,
        guest_info,
    })
}

export function getSessionGroupDetail(id) {
    return request.post('./index.php', {
        function: 'getSessionGroupDetail',
        id
    })
}

export function updateSessionGroupGuests(session_id, guest_ids) {
    return request.post('./index.php', {
        function: 'updateSessionGroupGuests',
        session_id,
        guest_ids
    })
}

export function getExhibitors(meeting_id) {
    return request.post('./index.php', {
        function: 'getExhibitors',
        meeting_id
    })
}
export function removeExhibitor(id) {
    return request.post('./index.php', {
        function: 'removeExhibitor',
        id
    })
}

export function updateExhibitor(params) {
    return request.post('./index.php', {
        function: 'updateExhibitor',
        id: params.id,
        booth: params.booth,
        view_points: params.view_points
    })
}

export function getTickets(meeting_id) {
    return request.post('./index.php', {
        function: 'getTickets',
        meeting_id
    })
}

export function removeTicket(id) {
    return request.post('./index.php', {
        function: 'removeTicket',
        id
    })
}

export function updateTicket(params) {
    return request.post('./index.php', {
        function: 'updateTicket',
        id: params.id,
        meeting_id: params.meeting_id,
        name: params.name,
        type: params.type,
        price: params.price,
        number: params.number,
    })
}

export function getSessionPPTAttachments(params) {
    return request.post('./index.php', {
        function: 'getSessionPPTAttachments',
        ...params
    })
}

export function updateSessionPPTAttachment(params) {
    return request.post('./index.php', {
        function: 'updateSessionPPTAttachment',
        ...params
    })
}
export function removeSessionPPTAttachment(params) {
    return request.post('./index.php', {
        function: 'removeSessionPPTAttachment',
        ...params
    })
}

export function getSessionPPTs(params) {
    return request.post('./index.php', {
        function: 'getSessionPPTs',
        ...params
    })
}
export function updateSessionPPT(params) {
    return request.post('./index.php', {
        function: 'updateSessionPPT',
        ...params
    })
}
export function removeSessionPPT(params) {
    return request.post('./index.php', {
        function: 'removeSessionPPT',
        ...params
    })
}
export function getSessionPPT(params) {
    return request.post('./index.php', {
        function: 'getSessionPPT',
        ...params
    })
}

export function getMeetingRooms(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingRooms',
        meeting_id
    })
}
export function removeMeetingRoom(id) {
    return request.post('./index.php', {
        function: 'removeMeetingRoom',
        id
    })
}

export function updateMeetingRoom(params) {
    return request.post('./index.php', {
        function: 'updateMeetingRoom',
        id: params.id,
        meeting_id: params.meeting_id,
        name: params.name,
        location: params.location,
        room_size: params.room_size,
        arrangement: params.arrangement
    })
}

export function updateMeetingRemind(params) {
    return request.post('./index.php', {
        function: 'updateMeetingRemind',
        meeting_id: params.meeting_id,
        after_register: params.after_register,
        before_one_day: params.before_one_day,
        before_one_hour: params.before_one_hour
    })
}

export function getPPTNotes(params) {
    return request.post('/Canhaohui/index.php', {
        function: 'getPPTNotes',
        ...params
    })
}
export function getMeetingGuests(params) {
    return request.post('./index.php', {
        function: 'getMeetingGuests',
        ...params
    })
}

export function loginByGuest(params) {
    return request.post('./index.php', {
        function: 'loginByGuest',
        ...params
    })
}

export function loginByPartner(organizer_email, organizer_password) {
    return request.post('./index.php', {
        function: 'loginByPartner',
        organizer_email,
        organizer_password
    })
}

export function createLoginOPT(email) {
    return request.post('./index.php', {
        function: 'createLoginOPT',
        email
    })
}

export function getGuestSessions(params) {
    return request.post('./index.php', {
        function: 'getGuestSessions',
        ...params
    })
}
export function updatePPTNotes(params) {
    return request.post('./index.php', {
        function: 'updatePPTNotes',
        ...params
    })
}

export function getMeetingParticipants(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingParticipants',
        meeting_id
    })
}

export function addMeetingApplication(params) {
    return request.post('./index.php', {
        function: 'addMeetingApplication',
        ...params,
        // meeting_id: params.meeting_id,
        // ticket_id: params.ticket_id,
        // user_mobile: params.user_mobile,
        // user_name: params.user_name,
        // user_email: params.user_email,
        // open_id: params.open_id,
        // ticket_type: params.ticket_type
    })
}

export function getMeetingHandbooks(meeting_id) {
    return request.post('./index.php', {
        function: 'getMeetingHandbooks',
        meeting_id
    })
}

export function updateMeetingHandbook(params) {
    return request.post('./index.php', {
        function: 'updateMeetingHandbook',
        ...params
    })
}

export function sendServiceMessage(messageData) {
    return request.post('./index.php', {
        function: 'sendServiceMessage',
        message: messageData
    })
}

export function getServiceMessagesByDialogue(talkers_array) {
    return request.post('./index.php', {
        function: 'getServiceMessagesByDialogue',
        talkers: talkers_array
    })
}


export function getPartnerMessageUsers(partner_id) {
    return request.post('./index.php', {
        function: 'getPartnerMessageUsers',
        partner_id
    })
}

export function getOrganizerPartners(organizer_id) {
    return request.post('./index.php', {
        function: 'getOrganizerPartners',
        organizer_id
    })
}

export function addMettingPartner(meeting_id, partner_id, organizer_id) {
    return request.post('./index.php', {
        function: 'addMettingPartner',
        meeting_id,
        partner_id,
        organizer_id
    })
}

export function getAllPartners() {
    return request.post('./index.php', {
        function: 'getAllPartners',
    })
}
export function removeMeetingPartner(id) {
    return request.post('./index.php', {
        function: 'removeMeetingPartner',
        id
    })
}

export function addMettingExhibitor(meeting_id, partner_id, organizer_id) {
    return request.post('./index.php', {
        function: 'addMettingExhibitor',
        meeting_id,
        partner_id,
        organizer_id
    })
}

export function uploadMeetingFlie(params) {
    const formData = new FormData()
    formData.append('function', 'updateFile')
    formData.append('file', params.file)
    formData.append('file_name', params.fileName)
    formData.append('meeting_id', params.meeting_id)

    return request.post('./index.php', formData)
}

export function getMeetingFlies(params) {
    return request.post('./index.php', {
        function: 'getFileList',
        meeting_id: params.meeting_id
    })
}
export function removeMeetingFlie(params) {
    return request.post('./index.php', {
        function: 'removeFile',
        meeting_id: params.meeting_id,
        id: params.id,
    })
}
export function editMeetingFlie(params) {
    return request.post('./index.php', {
        function: 'editFile',
        file_name: params.file_name,
        id: params.id,
    })
}

/** */
export function getPartnerMeetingProducts(params) {
    return request.post('./index.php', {
        function: 'getPartnerMeetingProducts',
        meeting_id: params.meeting_id,
        partner_id: params.partner_id
    })
}
/** */
export function getPartnerProduct(params) {
    return request.post('./index.php', {
        function: 'getPartnerProduct',
        product_id: params.product_id
    })
}
/** 新增、更新品牌产品 */
export function updatePartnerProduct(params) {
    return request.post('./index.php', {
        function: 'updatePartnerProduct',
        product_id: params.product_id,
        meeting_id: params.meeting_id,
        partner_id: params.partner_id,
        product_name: params.product_name,
        product_intro: params.product_intro,
    })
}
/** 删除品牌产品 */
export function removeProductInMeeting(params) {
    return request.post('./index.php', {
        function: 'removeProductInMeeting',
        product_id: params.product_id,
        meeting_id: params.meeting_id,
    })
}
export function addMeetingGuest(params) {
    return request.post('./index.php', {
        function: 'addMeetingGuest',
        ...params
    })
}

export function removeMeetingGuest(params) {
    return request.post('./index.php', {
        function: 'removeMeetingGuest',
        ...params
    })
}

// 获取通知列表
export function getNotificationList(params) {
    return request.post('./index.php', {
        function: 'getNotificationList',
        ...params
    })
}

// 获取通知详情

export function getNotificationDetail(params) {
    return request.post('./index.php', {
        function: 'getNotificationDetail',
        ...params
    })
}

// 更新通知
export function updateNotification(params) {
    return request.post('./index.php', {
        function: 'updateNotification',
        ...params
    })
}

// 删除通知
export function deleteNotification(params) {
    return request.post('./index.php', {
        function: 'deleteNotification',
        ...params
    })
}

export function staticsMessage(meeting_id) {
    return request.post('./index.php', {
        function: 'staticsMessage',
        meeting_id
    })
}


export function pushNotification(notification_id) {
    return request.post('./index.php', {
        function: 'pushNotification',
        notification_id
    })
}


//通用请求接口

export function postRequest(params) {
    return request.post('./index.php', params)
}

export function getWeChatPayQRCodeUrl(order_id) {
    return request.post('./index.php', {
        function: 'getWeChatPayQRCodeUrl',
        order_id
    })
}
export function getAliPayQrUrl(order_id) {
    return request.post("./index.php", {
        function: "getAliPayQrUrl",
        order_id,
    });
}

export function assignOrderToMeeting(order_id, meeting_id) {
    return request.post("./index.php", {
        function: "assignOrderToMeeting",
        order_id,
        meeting_id
    });
}

export function getOrganizerInfo(id) {
    return request.post("./index.php", {
        function: "getOrganizerInfo",
        id
    });
}

export function applyInvoice(order_id) {
    return request.post("./index.php", {
        function: "applyInvoice",
        order_id
    });
}

export function updateAgreement(param) {
    return request.post('./index.php', {
        function: 'updateAgreement',
        ...param
    })
}
export function removeAgreement(param) {
    return request.post('./index.php', {
        function: 'removeAgreement',
        ...param
    })
}
export function getAgreements(param) {
    return request.post('./index.php', {
        function: 'getAgreements',
        ...param
    })
}

export function setShowFile(param) {
    return request.post('./index.php', {
        function: 'setShowFile',
        ...param
    })
}
export function getShowFile(param) {
    return request.post('./index.php', {
        function: 'getShowFile',
        ...param
    })
}
export function removeShowFile(param) {
    return request.post('./index.php', {
        function: 'removeShowFile',
        ...param
    })
}